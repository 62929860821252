<template>
  <div class="min-h-full bg-gray-100">
    <nav-bar-r></nav-bar-r>
    <div class="py-7">
      <header>
        <div class="max-w-7xl mx-auto px-4 mb-0.5 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-black leading-tight text-o_purple-200">
            動態知識區塊鏈
          </h1>
        </div>
      </header>
      <div class="custom-h-inside max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="px-4 py-7 sm:px-0">
          <div id="contentScrollbar" class="custom-h-inside border-4 border-dashed border-o_pink rounded-lg p-1.5 overflow-auto">
            <router-view />
          </div>
        </div>
      </div>
    </div>
    <footer-r></footer-r>
  </div>
</template>

<style lang="scss">
/* scrollbar背景設置 */
#contentScrollbar::-webkit-scrollbar {
  width: 15px;
  /*底色*/
  background: #F5F5F5;
  /*漸層變化*/
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* scrollbar滑動controller設置 */
#contentScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 150px;
  background-color: #6154a5;
}
/* scrollbar corner設置(上下與左右滑動條會有個交錯正方形空白，設置這個才能處理 */
#contentScrollbar::-webkit-scrollbar-corner{
  background: #F5F5F5;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* scrollbar使用提示，使用拉動時候會變色 */
#contentScrollbar::-webkit-scrollbar-thumb:hover {
  background: #85d0a0;
}

.custom-h-inside {
  height: 1000px;
}
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  //background-color: #fe917d;
}
//.custom-h-inside {
//  height: 700px;
//}
.content_size {
  height: 60vh;
}
</style>
<script>
import NavBarR from '@/components/NavBarR'
import FooterR from '@/components/FooterR'
export default {
  components: { FooterR, NavBarR }
}
</script>
